import { template as template_58ac0390d4b04f4990c23419dc56f754 } from "@ember/template-compiler";
const FKLabel = template_58ac0390d4b04f4990c23419dc56f754(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
