import { template as template_9877d0b58ff74c339ed5c29f90da8e50 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_9877d0b58ff74c339ed5c29f90da8e50(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
