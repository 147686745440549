import { template as template_5069ec542a594d67877a08d9d21abf97 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5069ec542a594d67877a08d9d21abf97(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
